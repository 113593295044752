import React, { useEffect, useRef, useState } from "react";

import Navbar from "../../Navbar";
import Footer from "../../Footer";
import BlogDetailedImg from "../../Assets/Pictures/blog-detailed-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import BlogImg from "../../Assets/Pictures/blog-img.png";
import { BlogGet, ImageBaseUrl, SpecficUserBlog } from "../../Repository/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { truncateText } from "../../Utils/utils";
import ExpandableText from "../Typography/ExpandableText";
import HideText from "../Typography/HideText";
const BlogDetails = () => {
  const { id } = useParams();
  const [CreateBlog, setCreateBlog] = useState({});
  const navigate = useNavigate();

  const { token } = useSelector((state) => state?.login);
  const [blogList, setBlogList] = useState([]);

  const GetData = async () => {
    const response = await BlogGet(token);
    if (response && response.statusCode === 200) {
      setBlogList(response?.data);
    } else {
      setBlogList([]);
    }
  };
  const GetSpecificData = async () => {
    const response = await SpecficUserBlog(id, token);

    if (response && response.statusCode === 200) {
      setCreateBlog(response?.data);
    }
  };
  const scrollToTops = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    GetSpecificData();
  }, [id]);
  useEffect(() => {
    GetData();
  }, [token]);
  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="pt-5 blog-details-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="mb-5">
                <h1 className="font-bold">{CreateBlog?.title}.</h1>
                <div className="">
                  <span
                    onClick={() =>
                      window.open(`
                      ${ImageBaseUrl}${CreateBlog?.image}
                    `)
                    }
                    target="_blank"
                  >
                    <img
                      className="blog-detailed-img blog-img-des "
                      src={ImageBaseUrl + CreateBlog.image}
                      alt=""
                    /></span>

                </div>

              </div>
              <div className="">

                <div
                  className="faq-body"
                  dangerouslySetInnerHTML={{
                    __html: CreateBlog?.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <h1 className="font-bold mb-4">Related articles</h1>
              <div className="row ">
                {blogList
                  ?.filter(
                    (blog) => blog?.status === "active" && blog?._id !== id
                  )
                  ?.slice(0, 3)
                  ?.map((item, index) => (
                    <div className="col-lg-4" key={index}>
                      <div className="card-all-blogs">
                        <img
                          src={ImageBaseUrl + item.image}
                          className="card-img-top blog-img-des"
                          alt="..."
                        />
                        <div className="card-body">
                          <p className="card-text-allblogs heigth-short-blog">
                            {" "}
                            {/* <div
                              className="faq-body"
                              dangerouslySetInnerHTML={{
                                __html: truncateText(item?.content, 10),
                              }}
                            /> */}
                            <HideText
                              className="faq-body"
                              maxLength={120}
                              text={item?.content}
                            />
                          </p>

                          <div
                            // onClick={() => navigate(`/Blogs`)}
                            onClick={() => {
                              navigate(`/BlogDetails/${item?._id}`);
                              scrollToTops();
                            }}
                            className="allblogread"
                          >
                            View Blog
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* <div className="text-center mt-5">
                <a href="#Blogs" className="">
                  <button className="aboutrightbtn mt-3 ">
                    Get In Touch{" "}
                    <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                  </button>
                </a>
              </div> */}
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetails;
