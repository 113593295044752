import React, { useState } from "react";
import {
  NonEmptyValidation,
  LengthValidation,
  StringValidation,
  NumberValidation,
} from "../../../Utils/validate";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useNavigate } from "react-router-dom";
import { CreateBlogs, UploadFile } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg, warningMsg } from "../../../Utils/Toastify";
import { CloudFrontUrl, EditorOption } from "../../../Utils/utils";
import moment from "moment";
const BlogCreate = () => {
  const [CreateBlog, setCreateBlog] = useState({});

  const [validation, setValidation] = useState({});

  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const setData = (e, key) => {
    setCreateBlog({ ...CreateBlog, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [file, setFile] = useState("");
  const [blogfile, setBlogFile] = useState("");

  const handleFileChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    handleUploadFile(e.target.files[0], "authorImage");
  };
  const handleFileChangeBlog = (e) => {
    setBlogFile(URL.createObjectURL(e.target.files[0]));

    handleUploadFile(e.target.files[0], "image");
  };

  const handleUploadFile = async (fileURL, key) => {
    let formdata = new FormData();
    formdata.append("file", fileURL);
    formdata.append("path", "Blog/");

    const Response = await UploadFile(formdata);
   
    if (Response?.data?.statusCode === 200) {
      setData(Response?.data?.data?._id, key);
    
      successMsg(Response?.message);
    } else {
      errorMsg(Response.error);
    }
  };
  const handleEditorChange = (state, key) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setData(html, "content");
    }
  };
  const handleSubmit = async () => {
    let validate = {};

    validate.title = NonEmptyValidation(CreateBlog?.title);
    validate.author = StringValidation(CreateBlog?.author);
    validate.authorImage = NonEmptyValidation(CreateBlog?.authorImage);

    validate.content = NonEmptyValidation(CreateBlog?.content);
    validate.image = NonEmptyValidation(CreateBlog?.image);
    // validate.displayOrder = NonEmptyValidation(CreateBlog?.displayOrder);

    validate.publishedAt = NonEmptyValidation(CreateBlog?.publishedAt);

    validate.minutes = NumberValidation(CreateBlog?.minutes);

    setValidation(validate);

    console.log(validate, "validate");

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        title: CreateBlog.title,
        author: CreateBlog.author,
        authorImage: CreateBlog.authorImage,

        content: CreateBlog.content,
        publishedAt: CreateBlog.publishedAt,
        minutes: CreateBlog.minutes,
        image: CreateBlog.image,
        // displayOrder: CreateBlog.displayOrder,
        subject: CreateBlog.subject,
        message: CreateBlog.message,
      };
      console.log(Payload, "Payload");
      const response = await CreateBlogs(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setCreateBlog({});
        setValidation({});

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Blog Create
        </p>
        {/* <button
          className="admin-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          Create
        </button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Author Name <span className="hash">*</span>
              </label>

              <input
                type="text"
                placeholder="Enter  Author Name "
                onChange={(e) => {
                  setData(e.target.value, "author");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "author",
                    StringValidation(e.target.value)
                  );
                }}
              />

              <small className="validation-error error-space">
                {validation?.author?.message
                  ? ` Author Name ${validation?.author?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Author Image <span className="hash">*</span>
              </label>
              <input
                type="file"
                className="input-file"
                onChange={handleFileChange}
                accept="image/png,image/webp"
              />

              {file?.length > 0 && (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={file}
                  alt="Uploaded"
                />
              )}

              <small className="validation-error error-space">
                {validation?.authorImage?.message
                  ? ` Author Image ${validation?.authorImage?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Blog Image <span className="hash">*</span>
              </label>

              <input
                type="file"
                className="input-file"
                onChange={handleFileChangeBlog}
                accept="image/png,image/webp"
              />

              {blogfile?.length > 0 && (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={blogfile}
                  alt="Uploaded"
                />
              )}

              <small className="validation-error error-space">
                {validation?.image?.message
                  ? ` Blog Image ${validation?.image?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Title <span className="hash">*</span>
              </label>

              <input
                type="text"
                placeholder="Enter Title "
                onChange={(e) => {
                  setData(e.target.value, "title");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "title",
                    NonEmptyValidation(e.target.value)
                  );
                }}
               
              />

              <small className="validation-error error-space">
                {validation?.title?.message
                  ? `Title ${validation?.title?.message}`
                  : ""}
              </small>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Display Order <span className="hash">*</span>
              </label>

              <input
                type="number"
                placeholder="Enter  Display Order "
                onChange={(e) => {
                  setData(e.target.value, "displayOrder");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "displayOrder",
                    NumberValidation(e.target.value)
                  );
                }}
              />

              <small className="validation-error error-space">
                {validation?.displayOrder?.message
                  ? ` Display Order ${validation?.displayOrder?.message}`
                  : ""}
              </small>
            </div>
          </div> */}

          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Published Date <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="date"
                  className="fieldinput"
                  defaultValue={CreateBlog?.publishedAt}
                  onChange={(e) => {
                    setData(e.target.value, "publishedAt");
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.publishedAt?.message
                  ? ` Published Date ${validation?.publishedAt?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Minutes <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="fieldinput"
                  placeholder="Enter Minutes"
                  defaultValue={CreateBlog?.minutes}
                  onChange={(e) => {
                    setData(e.target.value, "minutes");
                  }}
                  onBlur={(e) => {
                    setValidationValue(
                      "minutes",
                      NumberValidation(e.target.value)
                    );
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.minutes?.message
                  ? `Minutes ${validation?.minutes?.message}`
                  : ""}
              </small>
            </div>
          </div>

          <div className=" col-lg-12 field">
            <div className="form">
              <label className="descript">
                Content<span className="hash">*</span>
              </label>
              <br />
              <div className="fieldinput">
                <Editor
                  editorState={editorState}
                  toolbar={EditorOption}
                  onEditorStateChange={handleEditorChange}
                />
              </div>

              {/* <textarea
                type="text"
                placeholder="Enter Content"
                required
                style={{ width: "100%" }}
                onChange={(e) => {
                  setData(e.target.value, "content");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "content",
                    NonEmptyValidation(e.target.value)
                  );
                }}
              /> */}
              <small className="validation-error error-space">
                {validation?.content?.message
                  ? `Content ${validation?.content?.message}`
                  : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="admin-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCreate;
