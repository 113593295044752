import {
  NonEmptyValidation,
  LengthValidation,
  StringValidation,
  NumberValidation,
} from "../../../Utils/validate";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateBlogs,
  ImageBaseUrl,
  SpecficBlog,
  UpdateBlog,
  UploadFile,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg, warningMsg } from "../../../Utils/Toastify";
import { CloudFrontUrl, EditorOption } from "../../../Utils/utils";
import moment from "moment";

import { useEffect, useState } from "react";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

//forEditor
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}
const BlogUpdate = () => {
  const [CreateBlog, setCreateBlog] = useState({});

  const [validation, setValidation] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const GetSpecificData = async () => {
    const response = await SpecficBlog(id, token);
    console.log(response);
    if (response && response.statusCode === 200) {
      let dummy = response?.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.content) {
        let blocksFromHtml = htmlToDraft(dummy.content);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.content = cmsDescription;
      setCreateBlog(dummy);
    }
  };
  useEffect(() => {
    GetSpecificData();
  }, [id]);
  const setData = (e, key) => {
    setCreateBlog({ ...CreateBlog, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const [file, setFile] = useState(null);
  const [blogfile, setBlogFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    handleUploadFile(e.target.files[0], "authorImage");
  };
  const handleFileChangeBlog = (e) => {
    setBlogFile(URL.createObjectURL(e.target.files[0]));

    handleUploadFile(e.target.files[0], "image");
  };

  const handleUploadFile = async (fileURL, key) => {
    let formdata = new FormData();
    formdata.append("file", fileURL);
    formdata.append("path", "Blog/");

    const Response = await UploadFile(formdata);
    console.log(Response?.data?.statusCode);
    if (Response?.data?.statusCode === 200) {
      setData(Response?.data?.data?._id, key);

      successMsg(Response?.data?.message);
    } else {
      errorMsg(Response.message);
    }
  };
  console.log(CreateBlog, "CreateBlog");

  const handleSubmit = async () => {
    let validate = {};

    validate.title = NonEmptyValidation(CreateBlog?.title);
    validate.author = StringValidation(CreateBlog?.author);
    validate.authorImage = NonEmptyValidation(CreateBlog?.authorImage);

    validate.content = NonEmptyValidation(
      CreateBlog?.content?.getCurrentContent()?.getPlainText().trim()
    );
    validate.image = NonEmptyValidation(CreateBlog?.image);
    // validate.displayOrder = NonEmptyValidation(CreateBlog?.displayOrder);

    validate.publishedAt = NonEmptyValidation(CreateBlog?.publishedAt);

    validate.minutes = NumberValidation(CreateBlog?.minutes);

    setValidation(validate);

    let isNotEmpty =
      CreateBlog.content && CreateBlog.content.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(CreateBlog.content.getCurrentContent())
      );

      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          _id: id,
          title: CreateBlog.title,
          author: CreateBlog.author,
          authorImage: CreateBlog.authorImage,

          content: html,
          publishedAt: CreateBlog.publishedAt,
          minutes: CreateBlog.minutes,
          image: CreateBlog.image,
          //   displayOrder: CreateBlog.displayOrder,
          subject: CreateBlog.subject,
          message: CreateBlog.message,
        };
        const response = await UpdateBlog(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response?.message);

          setValidation({});

          setTimeout(() => {
            navigate(-1);
          }, 1000);
        } else {
          errorMsg(response?.message);
        }
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Blog Update
        </p>
        {/* <button
          className="admin-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          Edit
        </button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Author Name <span className="hash">*</span>
              </label>

              <input
                type="text"
                placeholder="Enter  Author Name "
                value={CreateBlog?.author}
                onChange={(e) => {
                  setData(e.target.value, "author");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "author",
                    StringValidation(e.target.value)
                  );
                }}
              />

              <small className="validation-error error-space">
                {validation?.author?.message
                  ? ` Author Name ${validation?.author?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Author Image <span className="hash">*</span>
              </label>

              <input
                type="file"
                className="input-file d-none"
                id="Authoreditimg"
                onChange={handleFileChange}
                accept="image/png,image/webp"
              />
              <label for="Authoreditimg" className="alt-btn">
                Upload Author Image
              </label>

              {/* <button type="submit">Upload</button> */}

              {file?.length > 0 ? (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={file}
                  alt="Uploaded"
                />
              ) : (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={ImageBaseUrl + CreateBlog?.authorImage}
                  alt="Uploaded"
                />
              )}

              <small className="validation-error error-space">
                {validation?.authorImage?.message
                  ? ` Author Image ${validation?.authorImage?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Blog Image <span className="hash">*</span>
              </label>

              <input
                type="file"
                className="input-file d-none"
                onChange={handleFileChangeBlog}
                id="blogeditimg"
                accept="image/png,image/webp"
              />
              <label for="blogeditimg" className="alt-btn">
                Upload Blog Image
              </label>
              {/* <button type="submit">Upload</button> */}

              {blogfile?.length > 0 ? (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={blogfile}
                  alt="Uploaded"
                />
              ) : (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={ImageBaseUrl + CreateBlog?.image}
                  alt="Uploaded"
                />
              )}

              <small className="validation-error error-space">
                {validation?.image?.message
                  ? ` Blog Image ${validation?.image?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Title <span className="hash">*</span>
              </label>

              <input
                type="text"
                value={CreateBlog?.title}
                placeholder="Enter Title "
                onChange={(e) => {
                  setData(e.target.value, "title");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "title",
                    NonEmptyValidation(e.target.value)
                  );
                }}
              />

              <small className="validation-error error-space">
                {validation?.title?.message
                  ? `Title ${validation?.title?.message}`
                  : ""}
              </small>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Display Order <span className="hash">*</span>
              </label>

              <input
                type="number"
                placeholder="Enter  Display Order "
                value={CreateBlog?.displayOrder}
                onChange={(e) => {
                  setData(e.target.value, "displayOrder");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "displayOrder",
                    NumberValidation(e.target.value)
                  );
                }}
              />

              <small className="validation-error error-space">
                {validation?.displayOrder?.message
                  ? ` Display Order ${validation?.displayOrder?.message}`
                  : ""}
              </small>
            </div>
          </div> */}

          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Published Date <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="date"
                  className="fieldinput"
                  value={moment(CreateBlog?.publishedAt).format("yyyy-MM-DD")}
                  onChange={(e) => {
                    setData(e.target.value, "publishedAt");
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.publishedAt?.message
                  ? ` Published Date ${validation?.publishedAt?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Minutes <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="fieldinput"
                  placeholder="Enter Minutes"
                  value={CreateBlog?.minutes}
                  onChange={(e) => {
                    setData(e.target.value, "minutes");
                  }}
                  onBlur={(e) => {
                    setValidationValue(
                      "minutes",
                      NumberValidation(e.target.value)
                    );
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.minutes?.message
                  ? `Minutes ${validation?.minutes?.message}`
                  : ""}
              </small>
            </div>
          </div>

          <div className=" col-lg-12 field">
            <div className="form">
              <label>
                Content<span className="hash">*</span>
              </label>
              <br />
              <div className="fieldinput">
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={EditorOption}
                  editorState={CreateBlog?.content}
                  onEditorStateChange={(e) => setData(e, "content")}
                />
              </div>
              {/* <textarea
                type="text"
                placeholder="Enter Content"
                required
                style={{ width: "100%" }}
                value={CreateBlog?.content}
                onChange={(e) => {
                  setData(e.target.value, "content");
                }}
                onBlur={(e) => {
                  setValidationValue(
                    "content",
                    NonEmptyValidation(e.target.value)
                  );
                }}
              /> */}
              <small className="validation-error error-space">
                {validation?.content?.message
                  ? `Content ${validation?.content?.message}`
                  : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="admin-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogUpdate;
